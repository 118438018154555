#order-card {
  min-width: 450px;
  max-width: 480px;
}

.card-stripe {
  /* color: #fff; */
  background-color: #6772e5;
  min-width: 360px;
  max-width: 360px;
  /* background-color: #87bbfd; */
  /* border: 5px solid red; */
}

.card-stripe-element {
  color: #fff;
  background-color: #7795f8;
  width: 350px;
  padding: 5px;
  margin: 5px;
  /* background-color: #87bbfd; */
  /* border: 5px solid red; */
}

.stripe-card-element {
  height: 40px;
  display: flex;
  align-items: center;
}
.stripe-element {
  width: 100%;
  padding: 15px;
}
.btn-stripe {
  /* width: 300px; */
  /* height: 30px; */
  /* line-height: 1; */
  /* border-radius: 5px; */
  color: white;
  background-color: #6772e5;
  font-weight: bold;
  /* padding: 5px; */
}
.btn-stripe:hover {
  color: #6772e5;
  background-color: gold;
}

.strip-form-input {
  height: 1rem;
  background-color: red;
}
