.product-text-holder {
  height: 200px;
  overflow-y: scroll;
}
.product-text {
  width: 100%;
}

.card:hover {
  border: 2px solid;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
}

.img-container {
  transition: all 0.6s linear;
  position: relative;
  overflow: hidden;
}
.product-img {
  transition: all 0.6s linear;
  /* height: 120px; */
  /* height: fit-content; */
  width: 100%;
}
.img-container:hover .product-img {
  transform: scale(1.2);
}

#product-price {
  color: #1b7340;
  font-weight: bold;
}
#product-text-table {
  vertical-align: top;
  font-weight: normal;
}

#product-original-price {
  color: black;
  font-weight: 300;
}

.scrollable {
  overflow: auto;
  max-height: 200px;
}

.card-footer {
  /* background: transparent; */
  /* border-top: transparant; */
  /* transition: all 1s linear; */
  padding: 0 0;
}
.card-footer:hover {
  background: rgba(247, 247, 247);
}

.card-btn-add {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px 5px 0px 5px;
  background: var(--lightBlue);
  border: none;
  color: var(--mainWhite);
  font-size: 1rem;
  border-radius: 50%;
  /* transform: translate(100%, 100%); */
  /* transition: all 1s linear; */
}
.card-shelf-text {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.card-btn-minus {
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding: 0px 5px;
  background: none;
  border-width: 2px;
  border-color: orangered;
  color: orangered;
  font-size: 1rem;
  border-radius: 50%;
  /* transform: translate(100%, 100%); */
  /* transition: all 1s linear; */
}
.card-btn-qty {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1rem;
  padding: 0px 8px;
  background: red;
  border: none;
  color: var(--mainWhite);
  border-radius: 50%;
}
.card-btn-out {
  position: absolute;
  bottom: 0px;
  right: 0px;
  background: none;
  border-width: 2px;
  border-color: orange;
  color: orange;
}
.cart-btn:hover {
  color: var(--mainBlue);
  cursor: pointer;
}

.search-label {
  width: 100%;
}
