.cart-icon {
  border: 0px;
  color: orange;
}

.cart-btn-add {
  background: green;
  border-width: 0.5px;
  color: var(--mainWhite);
  border-radius: 0 50% 50% 0;
}
.cart-btn-minus {
  background: green;
  border-width: 0.5px;
  color: var(--mainWhite);
  border-radius: 50% 0 0 50%;
}
.cart-btn-num {
  border-width: 0.5px;
  border-radius: 0;
  /* padding-left: 20px;
  padding-right: 20px; */
  border-color: green;
}

.cart-btn-num-highlight {
  border-width: 0.5px;
  border-radius: 0;
  background-color: hotpink;
  /* padding-left: 20px;
  padding-right: 20px; */
  border-color: green;
}
