.parallax {
  /* The image used */
  background-image: url('home-bg.jpeg');

  /* Set a specific height */
  min-height: 300px;
  /* max-height: 800px; */
  /* height: 100%; */

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Turn off parallax scrolling for all tablets and phones. Increase/decrease the pixels if needed */
@media only screen and (max-device-width: 1366px) {
  .parallax {
    background-attachment: scroll;
  }
}

#home-header {
  font-family: 'Bodoni Moda';
  text-align: center;
  padding: 30px;
}

#home-carousel .carousel-control-prev-icon {
  align-self: flex-end;
  padding-bottom: 100px;
}

#home-carousel .carousel-control-next-icon {
  align-self: flex-end;
  padding-bottom: 100px;
}
