.container blockquote {
  margin-left: 2rem;
}

.overflow-auto {
  overflow: auto;
}

#img-w100 {
  width: 100%;
  height: auto;
}

#img-w80 {
  width: 80%;
  height: auto;
}

#img-w20 {
  width: 20rem;
  height: auto;
}

figcaption {
  text-align: center;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
