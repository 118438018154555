.navbar-header {
  position: relative;
  background: #1b7340;
  color: white;
  text-align: center;
  height: 80px;
}
.navbar-header h1 {
  text-align: center;
  font-family: 'Bodoni Moda';
  font-size: 2rem;
  font-weight: 400;
  line-height: 100px;
  margin-left: 100px;
  padding-bottom: 0;
}
html {
  /* this solved the problem that fixed header overlapping anchor bookmarks */
  scroll-padding-top: 50px; /* height of sticky header */
}

/* Navbar style */
.navbar {
  font-size: 1rem;
  font-weight: 300;
  background-color: #1b7340;
  /* height: 80px; */
  padding: 0;
  padding-right: 10px;
}

.navbar-brand {
  padding-top: 0;
}
/* brand img sit at bottom of navbar, but taller than navbar */
#navbar-brand-img {
  position: absolute;
  bottom: 0px;
  width: 160px;
  height: auto;
  padding: 15px 15px 10px 15px;
  transition: 0.5s;
}
/* small brand img for collapse */
#navbar-brand-img-sm {
  /* position: absolute; */
  bottom: 0px;
  width: 60px;
}

#navbar-nav {
  /* filling in navbar-brand */
  margin-left: 160px;
  transition: 0.5s;
}
#navbar-brand-filler {
  display: inline-block;
  width: 160px;
}

.nav-link {
  color: whitesmoke !important;
}
.nav-link:hover {
  background-color: darkgreen;
}

/* .dropdown-menu {
      right: 0;
      left: auto;
    } */

.navbar-button {
  width: 30px;
}

#dropdown-basic {
  background-color: inherit;
  border-width: 0;
  font-size: inherit;
  font-weight: inherit;
  text-align: start;
}
#dropdown-basic:hover {
  background-color: darkgreen;
}
#dropdown-menu {
  margin: 0px;
  background-color: #65c45a;
  color: whitesmoke;
}
#dropdown-menu-item:hover {
  background-color: darkgreen;
  color: white;
}
#navdropdown-item {
  margin: 0px;
}

.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}
#lblCartCount {
  font-size: 0.8rem;
  background: #ff0000;
  color: #fff;
  padding: 1px 4px;
  vertical-align: top;
  margin-left: -1px;
}

/* Footer style */
.nav-footer {
  background-color: #1b7340;
  font-size: 14px;
  color: white;
  text-align: center;
  border-top: 1px solid #e7e7e7;
  padding: 10px;
  padding-right: 30px;
  position: fixed;
  left: 0;
  bottom: 0;
  /* height: 40px; */
  width: 100%;
}

#span-left {
  float: left;
}

#span-right {
  float: right;
}
