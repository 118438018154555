:root {
  --mainBlue: #2a2a72;
  --lightBlue: #009ffd;
  --mainWhite: #f3f3f3;
  --mainDark: #232528;
  --mainYellow: #ffa400;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:100,100italic,300,300italic,400,400italic,500,500italic,700,700italic,900,900italic');
@import url('https://fonts.googleapis.com/css?family=Bodoni+Moda');

body {
  font-family: Roboto, 'Bodoni Moda', sans-serif !important;
  /* background-color: var(--mainWhite) !important; */
  /* background-color: #7bafd4 !important; */
  /* background-image: url('/bg_Home.jpg'); */
  font-weight: 300;
  background-size: cover;
  color: var(--mainDark) !important;
}

.text-blue {
  color: var(--mainBlue);
}
.text-bright {
  color: var(--lightBlue);
}

.btn-black {
  background: transparent;
  text-transform: capitalize;
  font-size: 0.8rem !important;
  color: var(--mainDark);
  border-radius: 0 !important;
  border: 0.1rem solid var(--mainDark) !important;
}
.btn-black:hover {
  background: var(--mainDark) !important;
  color: var(--mainWhite) !important;
}

.form-group.required .control-label:after {
  content: '*';
  color: red;
}
