@import url(https://fonts.googleapis.com/css?family=Roboto:100,100italic,300,300italic,400,400italic,500,500italic,700,700italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Bodoni+Moda);
:root {
  --mainBlue: #2a2a72;
  --lightBlue: #009ffd;
  --mainWhite: #f3f3f3;
  --mainDark: #232528;
  --mainYellow: #ffa400;
}

body {
  font-family: Roboto, 'Bodoni Moda', sans-serif !important;
  /* background-color: var(--mainWhite) !important; */
  /* background-color: #7bafd4 !important; */
  /* background-image: url('/bg_Home.jpg'); */
  font-weight: 300;
  background-size: cover;
  color: #232528 !important;
  color: var(--mainDark) !important;
}

.text-blue {
  color: #2a2a72;
  color: var(--mainBlue);
}
.text-bright {
  color: #009ffd;
  color: var(--lightBlue);
}

.btn-black {
  background: transparent;
  text-transform: capitalize;
  font-size: 0.8rem !important;
  color: #232528;
  color: var(--mainDark);
  border-radius: 0 !important;
  border: 0.1rem solid #232528 !important;
  border: 0.1rem solid var(--mainDark) !important;
}
.btn-black:hover {
  background: #232528 !important;
  background: var(--mainDark) !important;
  color: #f3f3f3 !important;
  color: var(--mainWhite) !important;
}

.form-group.required .control-label:after {
  content: '*';
  color: red;
}

.parallax {
  /* The image used */
  background-image: url(/static/media/home-bg.8b59a7a2.jpeg);

  /* Set a specific height */
  min-height: 300px;
  /* max-height: 800px; */
  /* height: 100%; */

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Turn off parallax scrolling for all tablets and phones. Increase/decrease the pixels if needed */
@media only screen and (max-device-width: 1366px) {
  .parallax {
    background-attachment: scroll;
  }
}

#home-header {
  font-family: 'Bodoni Moda';
  text-align: center;
  padding: 30px;
}

#home-carousel .carousel-control-prev-icon {
  align-self: flex-end;
  padding-bottom: 100px;
}

#home-carousel .carousel-control-next-icon {
  align-self: flex-end;
  padding-bottom: 100px;
}

.product-text-holder {
  height: 200px;
  overflow-y: scroll;
}
.product-text {
  width: 100%;
}

.card:hover {
  border: 2px solid;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
}

.img-container {
  transition: all 0.6s linear;
  position: relative;
  overflow: hidden;
}
.product-img {
  transition: all 0.6s linear;
  /* height: 120px; */
  /* height: fit-content; */
  width: 100%;
}
.img-container:hover .product-img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

#product-price {
  color: #1b7340;
  font-weight: bold;
}
#product-text-table {
  vertical-align: top;
  font-weight: normal;
}

#product-original-price {
  color: black;
  font-weight: 300;
}

.scrollable {
  overflow: auto;
  max-height: 200px;
}

.card-footer {
  /* background: transparent; */
  /* border-top: transparant; */
  /* transition: all 1s linear; */
  padding: 0 0;
}
.card-footer:hover {
  background: rgba(247, 247, 247);
}

.card-btn-add {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px 5px 0px 5px;
  background: var(--lightBlue);
  border: none;
  color: var(--mainWhite);
  font-size: 1rem;
  border-radius: 50%;
  /* transform: translate(100%, 100%); */
  /* transition: all 1s linear; */
}
.card-shelf-text {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.card-btn-minus {
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding: 0px 5px;
  background: none;
  border-width: 2px;
  border-color: orangered;
  color: orangered;
  font-size: 1rem;
  border-radius: 50%;
  /* transform: translate(100%, 100%); */
  /* transition: all 1s linear; */
}
.card-btn-qty {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1rem;
  padding: 0px 8px;
  background: red;
  border: none;
  color: var(--mainWhite);
  border-radius: 50%;
}
.card-btn-out {
  position: absolute;
  bottom: 0px;
  right: 0px;
  background: none;
  border-width: 2px;
  border-color: orange;
  color: orange;
}
.cart-btn:hover {
  color: var(--mainBlue);
  cursor: pointer;
}

.search-label {
  width: 100%;
}

.navbar-header {
  position: relative;
  background: #1b7340;
  color: white;
  text-align: center;
  height: 80px;
}
.navbar-header h1 {
  text-align: center;
  font-family: 'Bodoni Moda';
  font-size: 2rem;
  font-weight: 400;
  line-height: 100px;
  margin-left: 100px;
  padding-bottom: 0;
}
html {
  /* this solved the problem that fixed header overlapping anchor bookmarks */
  scroll-padding-top: 50px; /* height of sticky header */
}

/* Navbar style */
.navbar {
  font-size: 1rem;
  font-weight: 300;
  background-color: #1b7340;
  /* height: 80px; */
  padding: 0;
  padding-right: 10px;
}

.navbar-brand {
  padding-top: 0;
}
/* brand img sit at bottom of navbar, but taller than navbar */
#navbar-brand-img {
  position: absolute;
  bottom: 0px;
  width: 160px;
  height: auto;
  padding: 15px 15px 10px 15px;
  transition: 0.5s;
}
/* small brand img for collapse */
#navbar-brand-img-sm {
  /* position: absolute; */
  bottom: 0px;
  width: 60px;
}

#navbar-nav {
  /* filling in navbar-brand */
  margin-left: 160px;
  transition: 0.5s;
}
#navbar-brand-filler {
  display: inline-block;
  width: 160px;
}

.nav-link {
  color: whitesmoke !important;
}
.nav-link:hover {
  background-color: darkgreen;
}

/* .dropdown-menu {
      right: 0;
      left: auto;
    } */

.navbar-button {
  width: 30px;
}

#dropdown-basic {
  background-color: inherit;
  border-width: 0;
  font-size: inherit;
  font-weight: inherit;
  text-align: start;
}
#dropdown-basic:hover {
  background-color: darkgreen;
}
#dropdown-menu {
  margin: 0px;
  background-color: #65c45a;
  color: whitesmoke;
}
#dropdown-menu-item:hover {
  background-color: darkgreen;
  color: white;
}
#navdropdown-item {
  margin: 0px;
}

.badge {
  padding-left: 9px;
  padding-right: 9px;
  border-radius: 9px;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}
#lblCartCount {
  font-size: 0.8rem;
  background: #ff0000;
  color: #fff;
  padding: 1px 4px;
  vertical-align: top;
  margin-left: -1px;
}

/* Footer style */
.nav-footer {
  background-color: #1b7340;
  font-size: 14px;
  color: white;
  text-align: center;
  border-top: 1px solid #e7e7e7;
  padding: 10px;
  padding-right: 30px;
  position: fixed;
  left: 0;
  bottom: 0;
  /* height: 40px; */
  width: 100%;
}

#span-left {
  float: left;
}

#span-right {
  float: right;
}

.container blockquote {
  margin-left: 2rem;
}

.overflow-auto {
  overflow: auto;
}

#img-w100 {
  width: 100%;
  height: auto;
}

#img-w80 {
  width: 80%;
  height: auto;
}

#img-w20 {
  width: 20rem;
  height: auto;
}

figcaption {
  text-align: center;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.note-style {
  background-color: #f3f3f3;
  border: 0.04rem solid rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
}

#order-card {
  min-width: 450px;
  max-width: 480px;
}

.card-stripe {
  /* color: #fff; */
  background-color: #6772e5;
  min-width: 360px;
  max-width: 360px;
  /* background-color: #87bbfd; */
  /* border: 5px solid red; */
}

.card-stripe-element {
  color: #fff;
  background-color: #7795f8;
  width: 350px;
  padding: 5px;
  margin: 5px;
  /* background-color: #87bbfd; */
  /* border: 5px solid red; */
}

.stripe-card-element {
  height: 40px;
  display: flex;
  align-items: center;
}
.stripe-element {
  width: 100%;
  padding: 15px;
}
.btn-stripe {
  /* width: 300px; */
  /* height: 30px; */
  /* line-height: 1; */
  /* border-radius: 5px; */
  color: white;
  background-color: #6772e5;
  font-weight: bold;
  /* padding: 5px; */
}
.btn-stripe:hover {
  color: #6772e5;
  background-color: gold;
}

.strip-form-input {
  height: 1rem;
  background-color: red;
}

.cart-icon {
  border: 0px;
  color: orange;
}

.cart-btn-add {
  background: green;
  border-width: 0.5px;
  color: var(--mainWhite);
  border-radius: 0 50% 50% 0;
}
.cart-btn-minus {
  background: green;
  border-width: 0.5px;
  color: var(--mainWhite);
  border-radius: 50% 0 0 50%;
}
.cart-btn-num {
  border-width: 0.5px;
  border-radius: 0;
  /* padding-left: 20px;
  padding-right: 20px; */
  border-color: green;
}

.cart-btn-num-highlight {
  border-width: 0.5px;
  border-radius: 0;
  background-color: hotpink;
  /* padding-left: 20px;
  padding-right: 20px; */
  border-color: green;
}

